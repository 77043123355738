var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "params-body"
  }, [_c('div', {
    staticClass: "card mt-3"
  }, [_c('b-form', {
    staticClass: "form"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Дата",
      "label-for": "work_period"
    }
  }, [_c('date-picker', {
    staticClass: "elm-calendar",
    attrs: {
      "id": "work_period",
      "placeholder": "Выберите дату",
      "clearable": false
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Фильтр",
      "label-for": "doc_operations"
    }
  }, [_c('i-select', {
    attrs: {
      "id": "doc_operations"
    }
  }, [_c('i-option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v("Выбор 1")]), _c('i-option', {
    attrs: {
      "value": "2"
    }
  }, [_vm._v("Выбор 2")])], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }