var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('reconciliation-act-modal', {
    attrs: {
      "supplier": _vm.supplier
    }
  }), _c('div', {
    staticClass: "b-table__wrapper"
  }, [_c('div', {
    staticClass: "b-table__header",
    staticStyle: {
      "display": "flex",
      "justify-content": "flex-end"
    }
  }, [_c('div', {
    staticClass: "b-table__filter"
  }), _c('div', {
    staticClass: "b-table__actions"
  }, [!_vm.entity ? _c('b-button', {
    staticClass: "btn-brand cursor",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.add_contractors
    }
  }, [_c('img', {
    staticClass: "mr",
    attrs: {
      "src": "/img/icons/btn-plus.svg",
      "alt": ""
    }
  }), _vm._v(" Добавить контрагента ")]) : _vm._e()], 1)]), _c('b-collapse', {
    attrs: {
      "id": "collapse-filters"
    }
  }, [_c('filters')], 1), _c('div', {
    staticClass: "b-table__content mb-4"
  }, [_c('div', {
    staticClass: "table-filter"
  }, [_c('b-form-input', {
    staticClass: "filter-search",
    attrs: {
      "type": "text",
      "placeholder": "Поиск"
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  })], 1), _c('b-table', {
    ref: "table_suppliers",
    staticClass: "table-suppliers",
    attrs: {
      "fields": _vm.fields,
      "items": _vm.suppliers_list,
      "busy": _vm.loading,
      "empty-filtered-text": "Ничего не найдено",
      "show-empty": ""
    },
    on: {
      "row-clicked": _vm.edit_contractors
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center d-flex justify-content-center align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "variant": "primary",
            "label": "Загрузка..."
          }
        }), _c('div', {
          staticClass: "ml-3"
        }, [_c('strong', [_vm._v("Загрузка...")])])], 1)];
      },
      proxy: true
    }, {
      key: "head(id)",
      fn: function () {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.select_all,
            callback: function ($$v) {
              _vm.select_all = $$v;
            },
            expression: "select_all"
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(id)",
      fn: function (data) {
        return [_c('b-form-checkbox', {
          staticClass: "cursor",
          attrs: {
            "value": data.value
          },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v;
            },
            expression: "selected"
          }
        })];
      }
    }, {
      key: "cell(name)",
      fn: function (data) {
        return [_c('div', {
          staticClass: "name cursor"
        }, [_vm._v(" " + _vm._s(data.item.name) + " ")]), _c('div', {
          staticClass: "data"
        }, [_c('span', [_vm._v("ИНН: " + _vm._s(data.item.inn))]), _c('span', [_vm._v("КПП: " + _vm._s(data.item.kpp))])])];
      }
    }, {
      key: "cell(contacts)",
      fn: function (data) {
        return [_vm.selected_phone(data.value) ? [_c('div', {
          staticClass: "d-flex cursor b-phone",
          on: {
            "click": _vm.toggle_contact_info
          }
        }, [_vm._v(" " + _vm._s(_vm.selected_phone(data.value)) + " "), _c('img', {
          attrs: {
            "src": "/img/icons/user_icon.svg",
            "alt": ""
          }
        })])] : [_vm._v("-")]];
      }
    }, {
      key: "cell(address_entity)",
      fn: function (_ref) {
        var value = _ref.value;
        return [value.city ? _c('div', [_vm._v(" " + _vm._s(_vm.getAddress(value)) + " ")]) : _c('div', [_vm._v("-")])];
      }
    }, {
      key: "empty",
      fn: function (scope) {
        return [_c('p', {
          staticClass: "center mt-3"
        }, [_vm._v(_vm._s(scope.emptyFilteredText))])];
      }
    }, {
      key: "table-caption",
      fn: function () {
        return [_vm.suppliers_list.length > 0 ? _c('div', {
          staticClass: "p-2"
        }, [_vm._v(" Всего " + _vm._s(_vm.totalItem) + " записей ")]) : _vm._e()];
      },
      proxy: true
    }])
  })], 1), _c('table-navbar', {
    attrs: {
      "items": _vm.selected,
      "items_name": "supplier",
      "hide_print": true,
      "clear_selected": _vm.clearSelected
    },
    on: {
      "remove_items": _vm.remove_items,
      "open-reconciliation-act-modal": _vm.openReconciliationActModal
    }
  }), _c('pagination', {
    staticClass: "constractors-pagination",
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      "total": _vm.totalItem,
      "table_name": "contractors"
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }