<template>
  <section>

    <reconciliation-act-modal :supplier="supplier" />

    <!-- <div class="content__header">{{ title }}</div> -->
    <div class="b-table__wrapper">
      <div
        style="display: flex; justify-content: flex-end"
        class="b-table__header"
      >
        <div class="b-table__filter">
          <!-- <b-button class="btn-filters cursor" v-b-toggle.collapse-filters>
            <img src="/img/icons/btn-settings.svg" alt="" />
            Фильтры
            <b-badge>0</b-badge>
          </b-button> -->
        </div>
        <div class="b-table__actions">
          <b-button
            v-if="!entity"
            class="btn-brand cursor"
            variant="primary"
            @click="add_contractors"
          >
            <img
              src="/img/icons/btn-plus.svg"
              class="mr"
              alt=""
            />
            Добавить контрагента
          </b-button>
          <!--
          <b-button class="btn-more ml-4" variant="light" @click="toggle_more">
            <img src="/img/icons/more.svg" alt="" />
          </b-button>
          -->
        </div>
      </div>
      <b-collapse id="collapse-filters">
        <filters />
      </b-collapse>
      <div class="b-table__content mb-4">
        <div class="table-filter">
          <b-form-input
            v-model="filter"
            type="text"
            class="filter-search"
            placeholder="Поиск"
          />
          <!--<b-button class="filter-search">
            <img src="/img/icons/settings.svg" alt="" />
          </b-button> -->
        </div>

        <b-table
          ref="table_suppliers"
          :fields="fields"
          :items="suppliers_list"
          :busy="loading"
          empty-filtered-text="Ничего не найдено"
          show-empty
          class="table-suppliers"
          @row-clicked="edit_contractors"
        >
          <template #table-busy>
            <div class="text-center d-flex justify-content-center align-items-center">
              <b-spinner
                variant="primary"
                label="Загрузка..."
              />
              <div class="ml-3"><strong>Загрузка...</strong></div>
            </div>
          </template>

          <template #head(id)>
            <b-form-checkbox v-model="select_all" />
          </template>

          <template #cell(id)="data">
            <b-form-checkbox
              v-model="selected"
              class="cursor"
              :value="data.value"
            />
          </template>

          <template #cell(name)="data">
            <div class="name cursor">
              {{ data.item.name }}
            </div>
            <div class="data">
              <span>ИНН: {{ data.item.inn }}</span>
              <span>КПП: {{ data.item.kpp }}</span>
            </div>
          </template>

          <template #cell(contacts)="data">
            <template v-if="selected_phone(data.value)">
              <div
                class="d-flex cursor b-phone"
                @click="toggle_contact_info"
              >
                {{ selected_phone(data.value) }}
                <img
                  src="/img/icons/user_icon.svg"
                  alt=""
                />
              </div>
            </template>
            <template v-else>-</template>
          </template>

          <template #cell(address_entity)="{ value }">
            <div v-if="value.city">
              {{ getAddress(value) }}
            </div>
            <div v-else>-</div>
          </template>

          <template #empty="scope">
            <p class="center mt-3">{{ scope.emptyFilteredText }}</p>
          </template>

          <template #table-caption>
            <div
              v-if="suppliers_list.length > 0"
              class="p-2"
            >
              Всего {{ totalItem }} записей
            </div>
          </template>
        </b-table>
      </div>
      <table-navbar
        :items="selected"
        items_name="supplier"
        :hide_print="true"
        :clear_selected="clearSelected"
        @remove_items="remove_items"
        @open-reconciliation-act-modal="openReconciliationActModal"
      />
      <pagination
        class="constractors-pagination"
        style="margin-top: 10px"
        :total="totalItem"
        table_name="contractors"
        @change_pagination="handlerPagination"
      />
    </div>
  </section>
</template>

<script>
  import * as R from 'ramda'
  import { mapActions } from 'vuex'
  import TableNavbar from '@/views/shared/components/table/TableNavbar'
  import Filters from '../components/Filters.vue'
  import Pagination from '@/views/shared/components/table/Pagination'
  import ReconciliationActModal from '@/views/core/modal/ReconciliationActModal'

  export default {
    name: 'Contractors',
    components: {
      TableNavbar,
      Pagination,
      Filters,
      ReconciliationActModal
    },
    props: {
      entity: Boolean
    },
    apollo: {
      PaginationEntities: {
        query: require('../gql/getPaginationEntities.gql'),
        notifyOnNetworkStatusChange: true,
        variables() {
          return {
            input: {
              select: this.entity ? 'ENTITY' : 'SUPPLIER',
              pagination: {
                skip: this.paginationSkip,
                take: this.pagination.take,
                search: this.filter
              }
            }
          }
        },
        fetchPolicy: 'no-cache',
        debounce: 500,
        result({ data }) {
          if (!data) return
          this.suppliers_list = data?.PaginationEntities?.entities ?? []
          this.totalItem = data.PaginationEntities.total
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
        },
        watchLoading(isLoading) {
          this.loading = isLoading
        }
      }
    },

    data: function () {
      return {
        title: this.$route.meta.title,
        loading: true,
        filter: null,
        select_all: false,
        selected: [],
        suppliers_list: [],
        totalItem: 0,
        paginationSkip: 0,
        pagination: {
          current_page: 1,
          take: 20
        },
        supplier: {}
      }
    },

    computed: {
      fields: function () {
        return [
          {
            key: 'id',
            label: '',
            isRowHeader: true,
            class: 'w-5 center'
          },
          {
            key: 'name',
            label: 'Наименование контрагента',
            class: 'w-35',
            sortable: true
          },
          {
            key: 'contacts',
            label: 'Номер телефона',
            class: 'w-20'
          },
          {
            key: 'address_entity',
            label: 'Юридический адрес',
            class: 'w-40'
          }
        ]
      }
    },

    watch: {
      pagination: {
        handler(newVal) {
          this.paginationSkip = (newVal.current_page - 1) * newVal.take
        },
        deep: true
      },

      filter() {
        this.paginationSkip = 0
      },

      select_all(newVal) {
        if (!newVal) return (this.selected = [])

        this.suppliers_list.forEach((item) => {
          const id = item.id
          if (!this.selected.includes(id)) this.selected.push(id)
        })
      }
    },

    created() {
      this.clear_current_supplier()
    },

    methods: {
      ...mapActions({
        clear_current_supplier: 'contractors/clearCurrentSupplier',
        remove_suplier: 'contractors/removeSupplier',
        set_current_supplier: 'contractors/SetCurrentSupplier'
      }),

      openReconciliationActModal(itemId) {
        this.supplier = this.suppliers_list.find((el) => el.id === itemId)
        this.$bvModal.show('reconciliation-act-modal')
      },

      clearSelected() {
        this.selected = []
        this.select_all = false
      },

      handlerPagination(option) {
        this.pagination.current_page = option.current_page
        this.pagination.take = option.take
      },

      getAddress(value) {
        const city = value.city
        const street = value.street
        const house = value.house
        const apartment = value.apartment
        return `${city ? city : ''}${street ? ', ' + street : ''}${house ? ', ' + house : ''}${
          apartment ? ', ' + apartment : ''
        }`
      },

      add_contractors() {
        this.$router.push({ name: 'contractos.add' })
      },

      edit_contractors(item) {
        const params = {
          id: item.id,
          is_edit: true,
          title: item.full_name
        }
        this.set_current_supplier(params)
        this.$router.push({ name: this.entity ? 'company.entities.edit' : 'contractos.edit', params: { id: item.id } })
      },

      toggle_contact_info() {
        this.$noty.show('Номер скопирован')
      },

      selected_phone(array) {
        if (array) {
          const pho = R.find(R.propEq('main', true))(array)
          const phone = pho && pho.phone ? pho.phone : '-'
          return phone
        }
      },

      toggleBusy() {
        this.isBusy = !this.isBusy
      },

      async remove_items(items) {
        try {
          await this.remove_suplier(items)
          await this.$apollo.queries.Suppliers.refetch()
        } catch (error) {
          this.$noty.show(error?.graphQLErrors[0]?.message)
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .b-table__header {
    margin-top: 0 !important;
    justify-content: space-between !important;
  }

  ::v-deep() {
    .table-suppliers {
      th {
        &:first-child {
          width: 10px;
        }

        tbody tr {
          cursor: pointer;
        }
      }

      td {
        .name {
          a {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #313131;
            text-decoration: none;
          }
        }

        .b-phone {
          .form-check {
            padding: 0;
            margin: 0;

            input {
              float: none;
              margin: 0;
            }
          }
        }
      }
    }
  }
  .table-suppliers {
    td {
      vertical-align: middle;

      .data {
        font-size: 12px;
        line-height: 24px;
        color: #888888;

        span {
          margin-right: 12px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .b-phone {
        justify-content: center;

        img {
          margin-left: 12px;
        }
      }

      &:first-child {
        text-align: center;
      }
    }
  }
</style>
