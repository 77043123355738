import { render, staticRenderFns } from "./Contractors.vue?vue&type=template&id=37680bea&scoped=true&"
import script from "./Contractors.vue?vue&type=script&lang=js&"
export * from "./Contractors.vue?vue&type=script&lang=js&"
import style0 from "./Contractors.vue?vue&type=style&index=0&id=37680bea&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37680bea",
  null
  
)

export default component.exports