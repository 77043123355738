<template>
  <div class="params-body">
    <div class="card mt-3">
      <b-form class="form">
        <b-form-group
          label="Дата"
          label-for="work_period"
        >
          <date-picker
            id="work_period"
            class="elm-calendar"
            placeholder="Выберите дату"
            :clearable="false"
          />
        </b-form-group>
        <b-form-group
          label="Фильтр"
          label-for="doc_operations"
        >
          <i-select id="doc_operations">
            <i-option value="1">Выбор 1</i-option>
            <i-option value="2">Выбор 2</i-option>
          </i-select>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      menu_list: {
        type: Array,
        default: () => []
      }
    },

    data: () => ({
      new_group: {
        name: '',
        parrent: '',
        type_selected: null,
        type_options: [
          { value: '1', label: 'Продукты питания' },
          { value: '2', label: 'Напитки' },
          { value: '3', label: 'Алкоголь' }
        ]
      }
    }),
    methods: {
      resetFilters() {
        // here reset filters
        this.$nextTick(() => {
          this.$bvModal.hide('modal-cashbox-sales-filters')
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .form {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 15px 18px;

    ::v-deep .form-group {
      width: calc(20% - 72px / 5);
      min-width: 150px;
      margin-bottom: 0;
    }
  }
  ::v-deep() {
    .modal-actions {
      display: flex;
      gap: 16px;
    }
  }
  // .modal-actions {
  //   display: flex;
  //   gap: 16px;
  // }
</style>
